/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/_variables.scss';
// @import '~bootstrap/scss/mixins/_caret.scss';
// @import '~bootstrap/scss/_dropdown.scss';

@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~td-emerald-standards/dist/emerald/assets/css/extra/bootstrap3-bridge.css';
@import './tdSupplement.scss';

/* Importing TD Emerald SCSS file. */
@import '~td-emerald-standards/dist/emerald/assets/css/td-standards.css';
// @import '~td-emerald-standards/src/scss/td-emerald-standards.scss'; // broken

@import '~td-emerald-standards/src/scss/core/td_variables';

$grid-breakpoint-lg: map_get($grid-breakpoints, "lg");
$grid-breakpoint-md: map_get($grid-breakpoints, "md");
$grid-breakpoint-sm: map_get($grid-breakpoints, "sm");

.td-logo {
  img {
    height: 43px;
    width: 49px;
  }
}

.p-relative {
  position: relative !important;
}

* {
  transition: all 0.25s ease;
}


.radio-bar {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 350px) {
    flex-direction: column;
    max-width: 100% !important;
  }
  label {
    display: flex;
    width: 30%;
    background-color: #fff;
    font-family: "TD Graphik Medium";
    font-weight: bold;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    height: 125px;
    border: 1px solid $td-colour-primary-light-1;
    border-radius: 10px;
    text-align: center;
    font-size: 15px;
    padding: 0;
    color: $td-colour-primary-light-1;
    @media screen and (max-width: $grid-breakpoint-sm) {
      min-width: unset !important;
      width: 100%;
      max-width: 96px;
      height: 100px;
    }
    &::before{
      content: '';
      border: 2px solid #fff;
      bottom: -2px;
      display: block;
      left: -2px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: -2px;
      transition: all 150ms ease-in-out;
    }

    &:hover {
      background-color: #F8FCF4;
      color: $td-colour-primary-light-1;
    }
    &:focus {
      background-color: #F8FCF4;
    }
  }
  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
    &:focus+label, &:checked+label {
      background: $td-colour-primary-light-1;
      color: white;
    }
  }
}


.td-single-select-button {
  max-width: 120px !important;
  min-width: 120px !important;
  height: 125px !important;
  color: #038203 !important;
  border-radius: 13px !important;
  border-width: 1px !important;
  padding: 0 !important;
  @media screen and (max-width: $grid-breakpoint-sm) {
    min-width: unset !important;
    width: 100% !important;
    max-width: 96px !important;
    height: 100px !important;
  }
  @media screen and (max-width: 350px) {
    flex-direction: column;
    max-width: 100% !important;
  }
}

.td-multi-single-select-buttons {
  width: 100%;
  max-width: 450px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  input[type=radio]:checked + label {
    color: #fff !important;
    background: $td-colour-primary-light-1;
  }
  input[type=checkbox], input[type=radio] {
    display: none;
    position: relative !important;
  }
  // input[type=checkbox]:checked:focus+label, input[type=radio]:checked:focus+label {
  //   outline: none !important;
  // }
  @media screen and (max-width: $grid-breakpoint-sm) {
    max-width: 345px;
    .select-button-wrapper {
      width: 30%;
      margin-bottom: 16px;
    }
  }
  @media screen and (max-width: 350px) {
    max-width: 50%;
    flex-direction: column;
    .select-button-wrapper {
      width: 100%;
    }
  }
}

.td-multi-single-select-buttons-wrapper {
  padding: 0;
  display: flex;
  justify-content: start;
  @media screen and (max-width: $grid-breakpoint-sm) {
    justify-content: center;
  }
}

@media print{
    .hide-for-print {
        display: none;
    }
}

@media screen{
  .hide-for-screen {
      display: none;
  }
}

.next-btn-wrapper {
  @media (min-width: $grid-breakpoint-sm) and (max-width: $grid-breakpoint-md) {
    margin: 0 auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.tooltip {
  transition: none;
}

.tooltip-inner {
  background-color: #1A5336 !important;
  font-family: "TD Graphik Semilight";
  font-size: 10px;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,.bs-tooltip-right .arrow::before {
  border-right-color: #1A5336 !important;
}

button.td-icon-information {
  outline: none;
  border: none;
  background: white;
}

