// For marketing injected cookie popup
// https://www.td.com/ca/en/personal-banking/system/v1.5/assets/css/default.css

.td_rq_modal-cookie:focus {
    outline       : 1px dotted #12422b;
    outline-offset: 1px
}

.td_rq_modal-cookie a.close-button:focus {
    outline       : 1px dotted #12422b !important;
    outline-offset: 1px
}

.td_rq_modal-cookie {
    bottom            : 50px;
    width             : 370px !important;
    position          : fixed;
    background        : #FFF;
    z-index           : 9999;
    -webkit-box-shadow: 0px 0px 10px 0px #b5b5b5;
    -moz-box-shadow   : 0px 0px 10px 0px #b5b5b5;
    box-shadow        : 0px 0px 10px 0px #b5b5b5
}

.td_rq_modal-cookie p,
.td_rq_modal-cookie a {
    font-size: 16px;
    font-size: 1rem;
    margin   : 0
}

.td_rq_modal-cookie .td-modal-cookie-content .close-button {
    position  : absolute;
    top       : 20px;
    right     : 26px;
    padding   : 0;
    background: transparent;
    border    : none;
    z-index   : 1
}

.td_rq_modal-cookie .td-modal-cookie-content .close-button .td-icon.icon-small {
    font-weight: bold;
    color      : #1c1c1c
}

.td_rq_modal-cookie .td-modal-cookie-title {
    margin-top  : 0;
    text-align  : left;
    padding-left: 0px
}

.td_rq_modal-cookie .td-modal-cookie-content {
    width: 370px
}

.td_rq_modal-cookie .td-modal-cookie-content .td-modal-cookie-content-inner {
    margin: 35px 20px 35px 20px
}

.td_rq_modal-cookie .td-modal-cookie-content .td-modal-cookie-content-inner a.td-link-standalone {
    display   : block;
    margin-top: 20px
}

@media only screen and (max-width: 63.94em) {
    .td_rq_modal-cookie {
        bottom: 50px;
        width : 483px !important
    }

    .td_rq_modal-cookie .td-modal-cookie-content {
        width: 100% !important
    }

    .td_rq_modal-cookie .td-modal-cookie-content .td-modal-cookie-content-inner {
        margin: 42px 20px 58px 20px
    }
}

@media (max-width: 47.94em) {
    .td_rq_modal-cookie {
        bottom: 0px;
        width : 100% !important;
        left  : 0px
    }

    .td_rq_modal-cookie p,
    .td_rq_modal-cookie a {
        font-size: 14px;
        font-size: .875rem
    }

    .td_rq_modal-cookie .td-modal-cookie-content .td-modal-cookie-content-inner {
        margin: 20px 10px 20px 10px
    }
}

@media print and (color) {
    .td_rq_modal-cookie {
        left      : auto !important;
        right     : 0 !important;
        bottom    : 50px !important;
        width     : 370px !important;
        position  : absolute !important;
        background: #fff !important;
        z-index   : 9999 !important;
        background: #fff !important;
        box-shadow: 0px 0px 10px 0px #b5b5b5 !important;
        border    : 1px solid #b5b5b5 !important
    }
}
